import { Box, Grid, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { Toastify } from "@/utils/toast";
import * as yup from "yup";
import "./Sante.step.styles.scss";
import { selectMemberProfile } from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";
import { apiSaveSante } from "@/features/profile/api/profile.api";
import { store } from "@/store";
import { updateMemberProfile } from "@/store/reducers/member/member.actions";
import { TMemberProfile } from "@/features/auth/types";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";

interface SanteStepProps {
  chooseStep: React.Dispatch<React.SetStateAction<number>>;
  scrollTop: () => void;
}

const SanteStep = ({ chooseStep, scrollTop }: SanteStepProps) => {
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    sante_physique: yup.string(),
  });

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      sante_physique:
        memberProfileFromStore?.infos?.healthAndDisability?.physicalHealth ||
        "",
      mal_occulte:
        memberProfileFromStore?.infos?.healthAndDisability?.occultDiseases ||
        "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);

      if (_.isEqual(formik.initialValues, values)) {
        Toastify(
          `Aucun changement apporté à votre santé et handicap.`,
          "warning",
          3000
        );
      } else {
        const dataToSend = {
          category: "healthAndDisability",
          physicalHealth: values.sante_physique.trim(),
          occultDiseases: values.mal_occulte.trim(),
        };
        console.log(dataToSend);
        santeStepSubmit(dataToSend);
      }
    },
  });

  const santeStepSubmit = (data: any) => {
    // Toastify('success',`As salemou 'alaikoum ${'Landry'}, tu es maintenant connectée.`);
    setLoading(true);

    apiSaveSante(data)
      .then((response: any) => {
        setLoading(false);
        console.log(response);
        const profile = response.data as TMemberProfile;
        store.dispatch(updateMemberProfile({ ...profile }));
        if (
          ["NEW", "NEW_EDITED"].includes(memberProfileFromStore.status.value)
        ) {
          Toastify(
            `Nous vous remercions pour avoir rempli votre profil, il va maintenant être vérifié par nos services sous 24 h , nous pourrons vous contacter par whatsapp ou mail en cas de besoin. Cordialement l'équipe Zawaj Sounnah`,
            "success",
            5000
          );
          navigate(`${routePaths.profileChildren.homeAbsolute}?userMode=new`, {
            replace: true,
          });
        } else {
          Toastify(
            `Les informations de votre santé et handicap ont bien été soumises à notre équipe de modération. `,
            "success",
            5000
          );
          navigate(
            `${routePaths.profileChildren.homeAbsolute}?userMode=edited`,
            {
              replace: true,
            }
          );
        }

        scrollTop();
      })
      .catch((error: any) => {
        setLoading(false);

        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const reset = () => {
    formik.setValues({
      sante_physique: "",
      mal_occulte: "",
    });
  };

  return (
    <form className="form-part-sante" onSubmit={formik.handleSubmit}>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={4} columnSpacing={2}>
          <Grid item xs={12} sm={12} lg={12}>
            <div className="text-area">
              <Typography className="text-area-label">
                {" "}
                Santé physique{" "}
              </Typography>
              <textarea
                className="text-area-input"
                placeholder="Maladie, malformation, handicap, autre"
                rows={4}
                name="sante_physique"
                onChange={formik.handleChange}
                value={formik.values.sante_physique}
              ></textarea>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <div className="text-area">
              <Typography className="text-area-label"> Mal occulte </Typography>
              <textarea
                className="text-area-input"
                placeholder="Djinn, waswas, sorcellerie, ..."
                rows={4}
                name="mal_occulte"
                onChange={formik.handleChange}
                value={formik.values.mal_occulte}
              ></textarea>
            </div>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        rowSpacing={4}
        columnSpacing={2}
        sx={{ justifyContent: "flex-end", marginTop: "30px" }}
      >
        <Grid item xs={12} sm={12} lg={3} xl={2}>
          <Button
            variant="outlined"
            color="tertiary"
            onClick={() => {
              reset();
            }}
          >
            {" "}
            Effacer
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} lg={5}>
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            variant="contained"
            color="primary"
            type="submit"
          >
            {" "}
            Valider et terminer
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default SanteStep;
